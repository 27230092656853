.navBar {
  background-color: #fff;
  border-bottom: 4px solid #cbcbcb;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
}
.navContainer {
  background-color: #001529;
}
.navMenu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: inherit;

  justify-content: center;
  align-items: center;
  max-width: 1080px;
  width: 100%;
  margin: auto;
  display: block;
  z-index: 100;
}
/* .navMenuItem:hover:after {
  height: 100%;
}
.navMenuItem:hover:before {
  height: 35%;
} */
.navMenuItem:hover > .navMenuLink {
  color: #d1d1d7;
}
.navMenuItem {
  /* transition: border 0.5s ease; */
  /* border-bottom: 0px solid #05293c; */
  padding: 1em 8px;
  text-align: center;
  box-sizing: border-box;
  /* width: 20%; */
  width: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  opacity: 0.8;
}
.navMenuItem:after {
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0%;
  background: #383c42;
  background: rgba(217, 217, 217, 0.15);
  z-index: 1;
  transition: width 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.navMenuItem:hover::after {
  width: 100%;
}
.navMenuItem.active::after {
  width: 100%;
}
.navMenuItem.active {
  border-left: 4px solid #fff;
  opacity: 1;
}
.navMenuItem .menu-svg-icon {
  fill: #000;
}
.svg-icon {
  border-radius: 50%;
  padding: 1px;
  background: white;
  width: 46px;
  height: 46px;
  padding: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navMenuItem .navMenuLink {
  color: #fff;
}
.navMenuLink {
  font-family: Montserrat, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  color: #767676;

  letter-spacing: 0.2px;
  padding: 4px;
}

/* .navMenuItem:after {
  background-color: rgba(109, 145, 186, 0.15);
  bottom: 0px;
  content: "";
  height: 0%;
  left: 0%;
  right: 0%;
  position: absolute;
  transition: height .7s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.navMenuItem:before {
  background-color: #05293c;
  bottom: 0px;
  content: "";
  height: 0%;
  left: 0%;
  right: 0%;
  position: absolute;
  transition: height 0.1s ease-in;
} */
.navButton {
  display: none;
  visibility: hidden;
}
.navMenuItem > svg {
  height: 50px !important;
  width: 50px !important;
  margin: 0px;
  border-radius: 50%;
  padding: 10px;
}
@media only screen and (max-width: 768px) {
  .navMenu {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: -200%;
    transition: left 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    /* padding-top: 60px; */
  }
  .navMenuItem {
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid #003d78;
  }
  .navMenuItem:first-child {
    border-top: 1px solid #ccc;
  }
  .navMenuItem > svg {
    height: 50px !important;
    width: 50px !important;
    margin: 0px;
  }
  .opened {
    left: 0%;
  }
  .navButton {
    display: inline;
    visibility: visible;
    width: 40px;
    height: 35px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    margin: auto 8px;
    opacity:.7;
  }
  .navBar > img {
    margin: 4px !important;
  }
}
.nav-logo {
  padding: 8px;
  height: 90px;
  color: #322c71;
  border-bottom: 4px solid;
  border-color: #fff;
  background: linear-gradient(#00284f, #001529);
  justify-content: start;
  align-items: center;
  text-align: center;
  display: flex;
  margin-bottom: 8px;
}
.nav-logo img {
  width: 80px;
  height: 80px;
}
.nav-logo div {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  font-weight: 700;
  transition: width 400ms ease-in-out;
}

/* .navButton {
  width: 60px;
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
} */

.navButton span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background: #002548;
  outline:2px solid #fff;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

/* Icon 3 */

.navButton span:nth-child(1) {
  top: 0px;
  outline:0px;
}

.navButton span:nth-child(2),
.navButton span:nth-child(3) {
  top: 11px;
}

.navButton span:nth-child(4) {
  top: 22px;
}

.navButton.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.navButton.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.navButton.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.navButton.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
  outline:0px;
}

:root {
  --font-primary-ar: 'Cairo', 'Tajawal', sans-serif;
  --font-secondary-ar: 'Almarai', sans-serif;
  --font-primary-fr: 'Montserrat', 'Poppins', sans-serif;
  --font-secondary-fr: 'Roboto', sans-serif;
}

[dir="rtl"] {
  font-family: var(--font-primary-ar);
}

[dir="rtl"] h1,
[dir="rtl"] h2,
[dir="rtl"] h3,
[dir="rtl"] h4,
[dir="rtl"] h5,
[dir="rtl"] h6 {
  font-family: var(--font-secondary-ar);
}

[dir="ltr"] {
  font-family: var(--font-primary-fr);
}

[dir="ltr"] h1,
[dir="ltr"] h2,
[dir="ltr"] h3,
[dir="ltr"] h4,
[dir="ltr"] h5,
[dir="ltr"] h6 {
  font-family: var(--font-secondary-fr);
}

/* ... existing code ... */

