.loginForm {
    max-width: 500px !important;
    background: white;
    width: 100%;
    margin: 20px auto 0;
    padding: 50px 50px 40px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    max-width: 440px;
    background: #ffffff;
    border-radius: 9px;
    /* box-shadow: 0px 0px 8px #cccccc73;
    border-top:6px solid #412a6c; */
}

