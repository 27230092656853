:root {
    --font-primary-ar: 'Cairo', 'Tajawal', sans-serif;
    --font-secondary-ar: 'Almarai', sans-serif;
    --font-primary-fr: 'Montserrat', 'Poppins', sans-serif;
    --font-secondary-fr: 'Roboto', sans-serif;
  }
  
  .results-container {
    font-family: var(--font-primary-ar);
    padding: 10px;
  }
  
  [dir="ltr"] .results-container {
    font-family: var(--font-primary-fr);
  }
  
  .ui.header {
    font-family: var(--font-secondary-ar) !important;
  }
  
  [dir="ltr"] .ui.header {
    font-family: var(--font-secondary-fr) !important;
  }
  
  .ui.header .sub.header {
    font-family: var(--font-primary-ar) !important;
    margin-top: 8px;
    font-size: 1rem;
  }
  
  [dir="ltr"] .ui.header .sub.header {
    font-family: var(--font-primary-fr) !important;
  }
  
  .student-info-item .header {
    font-family: var(--font-secondary-ar);
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  [dir="ltr"] .student-info-item .header {
    font-family: var(--font-secondary-fr);
  }
  
  .student-info-item .description {
    font-family: var(--font-primary-ar);
  }
  
  [dir="ltr"] .student-info-item .description {
    font-family: var(--font-primary-fr);
  }
  
  .results-table {
    font-family: var(--font-primary-ar);
  }
  
  [dir="ltr"] .results-table {
    font-family: var(--font-primary-fr);
  }
  
  .results-table th {
    font-family: var(--font-secondary-ar);
    font-weight: 600;
  }
  
  [dir="ltr"] .results-table th {
    font-family: var(--font-secondary-fr);
  }

  @media screen and (max-width: 768px) {
    .results-container {
      padding: 5px;
    }

    #area_to_print {
      width: 100% !important;
      max-width: 100% !important;
    }

    .results-table {
      font-size: 0.85em;
      width: 100% !important;
    }

    .results-table th,
    .results-table td {
      padding: 5px !important;
    }

    .student-info-item {
      margin-bottom: 10px !important;
    }

    .ui.grid {
      margin: 0 !important;
      width: 100% !important;
    }

    .ui.grid .column {
      padding: 5px !important;
    }

    .ui.container {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    /* تحسين عرض الأعمدة في الشاشات الصغيرة */
    .ui.grid .eight.wide.column {
      width: 100% !important;
    }

    .exames_list {
      width: 100% !important;
    }

    .mobile-cards {
      display: block;
    }

    .desktop-only {
      display: none;
    }

    .ui.fluid.card {
      margin-bottom: 1em !important;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
    }

    .ui.fluid.card.positive {
      background-color: #fcfff5 !important;
      box-shadow: 0 2px 4px rgba(0,150,0,0.1) !important;
    }

    .ui.fluid.card .header {
      font-size: 1.2em !important;
      margin-bottom: 0.5em !important;
      color: #2185d0 !important;
    }

    .ui.fluid.card .meta {
      font-size: 1em !important;
      color: #666 !important;
      margin-bottom: 0.5em !important;
    }

    .ui.fluid.card .description {
      font-size: 0.9em !important;
    }

    .ui.statistics.mini {
      margin: 1em 0 0 0 !important;
    }

    .ui.statistics.mini .statistic {
      margin: 0 !important;
      flex: 1;
    }

    .ui.statistics.mini .statistic .label {
      font-size: 0.8em !important;
      color: #666 !important;
      text-align: center !important;
    }

    .ui.statistics.mini .statistic .value {
      font-size: 1.2em !important;
      text-align: center !important;
    }

    .ui.tiny.label {
      margin: 0 !important;
      padding: 0.5em 1em !important;
      font-size: 0.8em !important;
    }

    /* إخفاء قسم الطباعة في العرض المحمول */
    .print-button {
        display: none !important;
    }

    /* إزالة الهوامش الإضافية عند إخفاء زر الطباعة */
    .ui.right.aligned.segment.desktop-only {
        display: none !important;
    }
  }

  /* تحسين عرض الجدول على الأجهزة المحمولة */
  @media screen and (max-width: 480px) {
    .results-table {
      font-size: 0.75em;
    }
    
    .print-button {
      width: 100%;
      margin-bottom: 10px !important;
    }

    #area_to_print {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .mobile-cards {
    display: none;
  }

  .desktop-only {
    display: table;
  }

  @media screen and (max-width: 768px) {
    .mobile-cards {
      display: block;
    }

    .desktop-only {
      display: none;
    }

    .ui.fluid.card {
      margin-bottom: 1em !important;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
    }

    .ui.fluid.card.positive {
      background-color: #fcfff5 !important;
      box-shadow: 0 2px 4px rgba(0,150,0,0.1) !important;
    }

    .ui.fluid.card .header {
      font-size: 1.2em !important;
      margin-bottom: 0.5em !important;
      color: #2185d0 !important;
    }

    .ui.fluid.card .meta {
      font-size: 1em !important;
      color: #666 !important;
      margin-bottom: 0.5em !important;
    }

    .ui.fluid.card .description {
      font-size: 0.9em !important;
    }

    .ui.statistics.mini {
      margin: 1em 0 0 0 !important;
    }

    .ui.statistics.mini .statistic {
      margin: 0 !important;
      flex: 1;
    }

    .ui.statistics.mini .statistic .label {
      font-size: 0.8em !important;
      color: #666 !important;
      text-align: center !important;
    }

    .ui.statistics.mini .statistic .value {
      font-size: 1.2em !important;
      text-align: center !important;
    }

    .ui.tiny.label {
      margin: 0 !important;
      padding: 0.5em 1em !important;
      font-size: 0.8em !important;
    }
  } 