@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');

.alert {
    padding:8px;
    border:1px solid red;
    border-radius: 8px;
}

.alert.danger {
    color:#A73636;
    border-color: #D14343;
    background-color: #FDF4F4;
    margin-top:8px;
}

.modal {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #00000096;
    position: fixed;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal_content {
    background-color: white;
    width: 50%;
    min-height: 250px;
    position: relative;
}

.modal_content .close {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.modal_content .close img {
    width: 24px;
    height: 24px;
}

.modal_content h1 {
    text-align: center;
    font-family: 'Cairo', sans-serif;
}

.modal_content .image img {
    width: 100%
}
