.container {
    padding:16px;
    justify-content: center;
    align-items: center;
    max-width: 1080px;
    width:100%;
    margin:auto;
    background-color: #fff;
}

.head1 {
    color:rgb(72,72,72);
    font-size:22px;
    border-left: 6px solid #637e9e;
    background-color: #412a6c0d;
    padding: 8px;
    display: block;
    color: rgb(99 95 108);
    font-size: 2rem;
    border-left: 6px solid #637e9e;
    background-color: #412a6c0d;
    padding: 8px;
}

.form_abs {
    direction: rtl;
    text-align: right;
    font-family: Roboto, Tajawal, sans-serif;
}

.newModal-content {
    background: unset !important;
    border: unset !important;
}

.modal-content .form-group {
    padding: 8px;
    border: 1px solid rgb(195 195 195 / 32%);
    border-radius: 8px;
    margin-bottom: 8px;
}

.myButton {
    outline: none;
    border: none;
    background: #637e9e;
    padding: 8px 18px;
    border-radius: 25px;
    color: #fff;
    transition: all 1s ease;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'Tajawal', 'Roboto', sans-serif;
    min-width: 142px;
}

.myButton.success {
    background: #33b55b;
}

.myButton:hover {
    background: #556578;
    color: #e5e5e5;
}

.modal-content h3 {
    text-align: right;
    font-size: 1.5rem;
    padding: 8px;
    font-family: 'Tajawal', 'Roboto', sans-serif;
}

.send_btn button{
    outline: unset !important;
    background: #141920 !important;
    color: #FFF !important;
    font-size: 1.1rem !important;
    font-family: 'Tajawal', Arial, sans-serif !important;
    border: unset !important;
    margin: 24px auto !important;
    padding: 12px 32px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
}

.send_btn button:hover{
    background: #6c8fc1 !important;
    color: #f1e9e9 !important;
}

/**
 * Overlay
 * -- only show for tablet and up
 */
@media only screen and (min-width: 40em) {
    .MyNewModal-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;
        visibility: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1), visibility 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    }
    .MyNewModal-overlay.active {
        opacity: 1;
        visibility: visible;
    }
}
/**
 * Modal
 */
.MyNewModal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    background-color: #fff;
    width: 600px;
    max-width: 75rem;
    min-height: 20rem;
    padding: 1rem;
    border-radius: 3px;
    opacity: 0;
    overflow-y: auto;
    visibility: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: scale(1.2);
    transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
}
.MyNewModal .close-modal {
    position: absolute;
    cursor: pointer;
    top: 5px;
    left: 15px;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1), transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    transition-delay: 0.3s;
}
.MyNewModal .close-modal svg {
    width: 1.75em;
    height: 1.75em;
}
.MyNewModal .MyNewModal-content {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: opacity 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    transition-delay: 0.3s;
}
.MyNewModal.active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}
.MyNewModal.active .MyNewModal-content {
    opacity: 1;
}
.MyNewModal.active .close-modal {
    transform: translateY(10px);
    opacity: 1;
}


.profile-data {
    display: flex;
    flex-direction: initial;
}

.profile-data-perso, .profile-data-email {
    width: 50%;
}

.profile-data-email {
    padding: 12px;
    background: #f9f9f9;
    border: 1px solid #cbcbcb;
    border-radius: 16px;
    direction: rtl;
    margin: 8px;
}

.profile-data-email .title {
    font-size: 18px;
    font-family: 'Cairo', sans-serif;
    direction: rtl;
    text-align: center;
}

.profile-data-email span {
    text-align: right;
    font-size: 14px;
    font-family: 'Cairo', sans-serif;
}

.area-email-how {
    margin-top: 26px;
}

.area-email-how p {
    font-size: 16px;
    font-family: 'Cairo','Lato', sans-serif;
    text-align: center;
}

.tag-email {
    width: 100%;
}

.tag-email > span:first-child {
    text-align: right;
}

.tag-email > span:last-child {
    text-align: center;
}

.email-input-data {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
}
.show-pass {
    background: #4c667f;
    padding: 5px;
    color: #FFF;
    cursor: pointer;
}

/**
 * Mobile styling
 */
@media only screen and (max-width: 760px) {
    h1 {
        font-size: 1.5rem;
    }

    .profile-data {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .profile-data-perso {
        margin-bottom: 20px;
    }

    .profile-data-email {
        width: 90%;
    }

    .MyNewModal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-overflow-scrolling: touch;
        border-radius: 0;
        transform: scale(1.1);
        padding: 0 !important;
    }

    .close-modal {
        right: 20px !important;
    }
}

