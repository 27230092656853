/* إضافة الخطوط */
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal_content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    position: relative;
    direction: rtl;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.modal_header h1 {
    font-family: 'Tajawal', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    margin: 0;
    color: #333;
}

.close_button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;
    padding: 0.5rem;
}

.close_button:hover {
    color: #333;
}

.video_container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
    overflow: hidden;
}

.video_container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.action_button {
    width: 100%;
    margin-top: 1rem !important;
    font-family: 'Tajawal', sans-serif !important;
    font-weight: 500 !important;
}

.action_button a {
    color: white !important;
    text-decoration: none;
    width: 100%;
    display: block;
}

.presentation {
    margin-bottom: 2rem;
}

.presentation_fr {
    margin-bottom: 2rem;
    text-align: left;
    direction: ltr;
    line-height: 1.6;
    font-family: 'Poppins', sans-serif;
}

.presentation_ar {
    margin-bottom: 2rem;
    text-align: right;
    direction: rtl;
    line-height: 1.8;
    font-family: 'Tajawal', sans-serif;
}

.presentation p {
    margin-bottom: 1rem;
    color: #333;
}

.presentation_fr p {
    font-size: 1rem;
    font-weight: 400;
}

.presentation_ar p {
    font-size: 1.1rem;
    font-weight: 500;
}

/* تحسين مظهر شريط التمرير */
.modal_content::-webkit-scrollbar {
    width: 8px;
}

.modal_content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.modal_content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.modal_content::-webkit-scrollbar-thumb:hover {
    background: #555;
} 