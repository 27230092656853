.attestationCardContainer {
    place-content: space-between;
}
.attestationCard {
    padding: 18px;
    background: #2185d0;
    border: 1px solid #e2e2e2;
    border-radius: 36px;
    color: white;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0 15px 15px 0;
    cursor: pointer;
    transition: all ease-in-out .5s;
}

.attestationCard:hover {
    background: #e2e2e2;
    border: 1px solid #2185d0;
    border-radius: 36px;
    color: black;
}

.hintText {
    font-size: 1rem;
    font-weight: 600;
    color: #001673;
    padding: 8px;
    background: #e9e9e9;
}

.attestation table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

.attestation table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
}

.attestation table tr {
    padding: .35em;
    border-bottom: 1px solid #dddddd;
}

.attestation table th,
.attestation table td {
    padding: .625em;
    text-align: center;
    min-height: 30px;
}

.attestation table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    .attestation table {
        border: 0;
    }

    .attestation table caption {
        font-size: 1.3em;
    }

    .attestation table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .attestation table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    .attestation table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
    }

    .attestation table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    .attestation table td:last-child {
        border-bottom: 0;
    }
}

:root {
  --font-primary-ar: 'Cairo', 'Tajawal', sans-serif;
  --font-secondary-ar: 'Almarai', sans-serif;
  --font-primary-fr: 'Montserrat', 'Poppins', sans-serif;
  --font-secondary-fr: 'Roboto', sans-serif;
}

.attestation-container {
  font-family: var(--font-primary-ar);
}

[dir="ltr"] .attestation-container {
  font-family: var(--font-primary-fr);
}

.attestation-title {
  font-family: var(--font-secondary-ar);
}

[dir="ltr"] .attestation-title {
  font-family: var(--font-secondary-fr);
}
