/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Base font settings */
.container {
    font-family: 'IBM Plex Sans Arabic', 'Poppins', sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    perspective: 1000px;
    animation: fadeIn 0.5s ease-in;
}

/* Section titles */
.sectionTitle {
    color: #2c3e50;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #eee;
    font-family: 'IBM Plex Sans Arabic', 'Poppins', sans-serif;
}

/* Language buttons */
.languageButton {
    font-family: inherit;
    padding: 8px 16px;
    border: 2px solid #007bff;
    background: transparent;
    color: #007bff;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: 0.95rem;
    transform-origin: center;
}

.languageButton.active {
    background: #007bff;
    color: white;
    font-weight: 600;
    animation: pulse 2s infinite;
}

.languageButton:hover {
    transform: scale(1.05);
}

/* Profile tags */
.profileTag > span:first-child {
    color: #6c757d;
    font-size: 0.9rem;
    font-weight: 500;
    display: block;
    margin-bottom: 6px;
    font-family: inherit;
}

.profileTag > span:last-child {
    color: #2c3e50;
    font-size: 1.1rem;
    font-weight: 600;
    background: transparent;
    padding: 0;
    font-family: inherit;
}

/* Email section */
.emailTitle {
    color: #2c3e50;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 16px;
    font-family: inherit;
}

/* Password field */
.passwordValue {
    flex: 1;
    font-family: 'Consolas', monospace;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
}

/* Action buttons */
.actionButton {
    background: #f8f9fa;
    color: #2c3e50;
    border: 1px solid #dee2e6;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
}

.actionButton:hover {
    background: #e9ecef;
    border-color: #ced4da;
    transform: translateY(-1px); 
}

.actionButton svg {
    width: 16px;
    height: 16px;
}

/* Copy button specific */
.actionButton.copyButton {
    background: #e3f2fd;
    color: #1976d2;
    border-color: #90caf9;
}

.actionButton.copyButton:hover {
    background: #bbdefb;
    border-color: #64b5f6;
}

/* Show/Hide password button specific */
.actionButton.togglePassword {
    background: #fff3e0;
    color: #f57c00;
    border-color: #ffe0b2;
}

.actionButton.togglePassword:hover {
    background: #ffe0b2;
    border-color: #ffb74d;
}

/* Active state for buttons */
.actionButton:active {
    transform: translateY(0);
}

/* RTL support for buttons */
[dir="rtl"] .actionButton {
    flex-direction: row-reverse;
}

/* Responsive design for buttons */
@media (max-width: 768px) {
    .actionButton {
        padding: 6px 12px;
        font-size: 0.85rem;
    }
    
    .actionButton svg {
        width: 14px;
        height: 14px;
    }
    
    .passwordActions {
        flex-wrap: wrap;
    }
}

/* Activation info */
.activationInfo {
    background: #e8f4f8;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
}

.activationInfo p {
    margin: 0;
    color: #2c3e50;
    font-size: 1rem;
    line-height: 1.5;
}

.activationInfo a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    display: inline-block;
    margin-top: 8px;
    font-family: 'Consolas', monospace;
}

.activationInfo a:hover {
    text-decoration: underline;
}

/* Copy success notification */
.copySuccess {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    animation: slideIn 0.3s ease-out, fadeOut 0.3s ease-in 1.7s forwards;
}

/* RTL specific styles */
[dir="rtl"] .container {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
}

[dir="rtl"] .profileTag > span:first-child {
    font-size: 0.95rem;
}

[dir="rtl"] .profileTag > span:last-child {
    font-size: 1.15rem;
}

/* LTR specific styles */
[dir="ltr"] .container {
    font-family: 'Poppins', sans-serif;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .sectionTitle {
        font-size: 1.35rem;
    }
    
    .profileTag > span:first-child {
        font-size: 0.85rem;
    }
    
    .profileTag > span:last-child {
        font-size: 1rem;
    }
    
    .emailTitle {
        font-size: 1.15rem;
    }
}

.subjectList {
    display: flex;
    max-width: 800px;
    align-items: center;
    /* border-radius: 8px; */
    /* border-left: 8px solid #637e9e; */
    margin: 4px;
    /* box-shadow: 0px 0px 3px 0px #8c97a1; */
    width: 100%;
    /* background-color: #eceff1; */
    /* height:60px; */
    border:2px solid #637e9e;
    border-radius: 6px;

}
.subjectList > div {
    margin:4px;
}
.listNumber {
    color: #495057;
    border: 2px solid #637e9e;
    display: flex;
    font-size: 20px;
    align-items: center;
    font-weight: 500;
    border-radius: 8px;
    justify-content: center;
    padding: 4px;
    
}
.listNumberSem {
    height: 100%;
    min-height: 100%;
    background-color: #637e9e;
    color: white;
    width: 100%;
    margin:0px;
    min-height: 52px;
    max-width: 52px;
    border-width: 0;
    border-radius: 50%;
    
    font-size: 1.2em;
    
}
.listGroup {
    color: #495057;
    display: flex;
    font-size: 20px;
    align-items: center;
    font-weight: 500;
    /* border-radius: 50%; */
    justify-content: center;
    padding: 4px;
    background: #637e9e;
    color: #ffffff;
    padding: 4px;
    border-radius: 8px;
}
.listTitle {
    color: #495057;
    font-size: 16px;
    font-weight: 700;
    margin-left: 16px !important;
    margin-right: 16px !important;
    padding-left: 8px;
}

.profileCard {
    color: #495057;
    font-size: 14   px;
    font-weight: 700;
    padding:16px;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
    width:100%;
    /* border:2px solid #ccc; */
    /* border-radius: 8px; */
    margin:auto;
    background-color: #fff;
   
    
}
.profileCard > div {
    display: flex;
    align-items: center;
}
.profileTag {
    margin:4px;
    display:flex;
    justify-content: center;
    flex-direction: column;
    display: inline-block;
    align-items: center;
    transition: all 0.3s ease;
    border-right: 4px solid #007bff;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.profileTag:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}
.profileTag > span:first-child {
    padding:4px;
    text-align: left;
    color:#727272;
    font-size:12px;
    display: block;
}
.profileTag > span:last-child {
    background-color: #efefef;
    color:#484848;
    padding:8px;
    display: block;
}

.profileTag::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #007bff;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.profileTag:hover::after {
    transform: scaleX(1);
}

.account-info {
    border-radius: 8px;
    transition: all 0.3s ease;
}

.activation-info {
    border-radius: 8px;
}

.btn-outline-secondary:hover {
    background-color: #6c757d;
    color: white;
}

/* Add these additional styles */
.card {
    border: none;
    transition: all 0.3s ease;
    background: #ffffff;
    border-radius: 12px;
    overflow: hidden;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

.card-title {
    color: #2c3e50;
    font-weight: 600;
    border-bottom: 2px solid #e9ecef;
    padding-bottom: 0.5rem;
}

.alert {
    border-left: 4px solid #17a2b8;
}

.alert-link {
    text-decoration: none;
    font-weight: 600;
}

.alert-link:hover {
    text-decoration: underline;
}

.btn-primary {
    background-color: #007bff;
    border: none;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
}

.btn-primary:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.profile-info {
    display: grid;
    gap: 1rem;
}

.profile-info .profileTag {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-info .profileTag strong {
    color: #495057;
    font-size: 0.9rem;
}

.profile-info .profileTag span {
    color: #212529;
    font-weight: 500;
}

@media (max-width: 768px) {
    .profile-info .profileTag {
        flex-direction: column;
        text-align: center;
        gap: 0.5rem;
    }
    
    .d-flex {
        flex-direction: column;
        gap: 0.5rem;
        align-items: stretch !important;
    }
    
    .btn-sm {
        width: 100%;
    }
}

/* Add these additional styles */
.profileTag span {
    font-weight: 500;
}

.profileTag strong {
    min-width: 120px;
    display: inline-block;
}

/* For RTL support */
[dir="rtl"] .profileTag {
    border-right: none;
    border-left: 4px solid #007bff;
}

.btn-outline-secondary {
    border-radius: 20px;
    padding: 0.25rem 1rem;
}

.alert-info {
    background-color: #e8f4f8;
    border-color: #17a2b8;
}

.alert-link {
    color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .profileTag {
        padding: 0.75rem !important;
    }
    
    .profileTag strong {
        min-width: 100%;
        margin-bottom: 0.5rem;
    }
}

/* Animation for hover states */
.card:hover .profileTag {
    border-right-color: #0056b3;
}

[dir="rtl"] .card:hover .profileTag {
    border-left-color: #0056b3;
}

.cardSection {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.08);
    padding: 24px;
    margin-bottom: 24px;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: transform 0.3s ease;
}

.cardSection:hover {
    transform: translateY(-4px);
}

.profileGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
    margin-bottom: 24px;
}

.profileTag {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 12px;
    transition: all 0.3s ease;
    border: none;
    margin: 0;
}

.profileTag:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.05);
}

.profileTag > span:first-child {
    color: #6c757d;
    font-size: 0.85rem;
    font-weight: 500;
    display: block;
    margin-bottom: 4px;
}

.profileTag > span:last-child {
    color: #2c3e50;
    font-size: 1rem;
    font-weight: 600;
    background: transparent;
    padding: 0;
}

.emailSection {
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
}

.passwordField {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    background: #f8f9fa;
    padding: 12px;
    border-radius: 8px;
}

.passwordActions {
    display: flex;
    gap: 8px;
}

.sectionLanguage {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
}

.activationInfo {
    background: #e8f4f8;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
}

.activationInfo p {
    margin: 0;
    color: #2c3e50;
}

.activationInfo a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
}

.activationInfo a:hover {
    text-decoration: underline;
}

/* RTL Support */
[dir="rtl"] .showPassButton {
    left: auto;
    right: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .profileGrid {
        grid-template-columns: 1fr;
    }
    
    .cardSection {
        padding: 16px;
    }
}

.actionButton::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 120%;
    background: radial-gradient(
        circle,
        rgba(255,255,255,0.2) 0%,
        rgba(255,255,255,0) 70%
    );
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 0.4s, opacity 0.3s;
}

.actionButton:active::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}

/* Update existing styles */
.copySuccess {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    animation: slideIn 0.3s ease-out, fadeOut 0.3s ease-in 1.7s forwards;
}

[dir="rtl"] .copySuccess {
    right: auto;
    left: 20px;
}

/* Add these new styles */
.container {
    perspective: 1000px;
}

.cardSection {
    backface-visibility: hidden;
    transform-style: preserve-3d;
}

.profileTag {
    position: relative;
    overflow: hidden;
}

.profileTag::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        45deg,
        rgba(255,255,255,0) 0%,
        rgba(255,255,255,0.1) 50%,
        rgba(255,255,255,0) 100%
    );
    transform: translateX(-100%);
    transition: transform 0.6s;
}

.profileTag:hover::after {
    transform: translateX(100%);
}

.actionButton {
    position: relative;
    overflow: hidden;
}

.actionButton::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 120%;
    background: radial-gradient(
        circle,
        rgba(255,255,255,0.2) 0%,
        rgba(255,255,255,0) 70%
    );
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 0.4s, opacity 0.3s;
}

.actionButton:active::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideIn {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

:root {
  --font-primary: 'Cairo', 'Tajawal', sans-serif;
  --font-secondary: 'Almarai', sans-serif;
}

body {
  font-family: var(--font-primary);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-secondary);
  font-weight: 700;
}

.title {
  font-family: var(--font-secondary);
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.subtitle {
  font-family: var(--font-primary);
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6;
}

