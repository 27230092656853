th {
    padding: 6px 8px;
    color: white;
    background: #637e9e;
    text-transform: uppercase;
}
.semester-row > td {
    font-weight: 700;
    /* border-bottom: 2px solid #637e9e; */
    background: #dedede;
}
td {
    padding: 6px;
    color: #484848;
    background: #f5f4f8;
    text-align: center;
}
td > span {
    text-overflow: ellipsis;
    width:50%;
}
td > span:first-child {
    text-align: left;
}
td > span:last-child {
    text-align: right;
}
table {
    width: 100%;
}
tr.hidden {
    display:none;
}